import React from 'react';

const Unauthorized = () => {
  return (
    <div className="flex flex-row justify-center items-center h-screen w-screen bg-white">
      <div className="flex flex-col justify-center items-center text-3xl gap-4 font-semibold">
        <img
          src="/assets/img/unauthorized-access-on-computer.png"
          className="h-3/12 w-3/12"
          alt="Unauthorized Access Denied"
        />
        <div className="text-red-600">Unauthorized</div>
      </div>
    </div>
  );
};

Unauthorized.propTypes = {};

Unauthorized.defaultProps = {};

export default Unauthorized;
