import React from 'react';
import { Routes, Route } from 'react-router-dom';
import routes from '../config/routes';
import DashboardRoute from './Dashboard';
import ReportsRoute from './Reports';
import SettingsPageRoute from './SettingsPage';
import HistoryRoute from './History';
import ManualSearchRoute from './ManualSearch';
import UnauthorizedRoute from '../views/Unauthorized';
import Fallback from './Fallback';

const Routing = () => (
  <Routes>
    <Route path={routes.dashboard} element={<DashboardRoute />} />
    <Route path={routes.reports} element={<ReportsRoute />} />
    <Route path={routes.settings} element={<SettingsPageRoute />} />
    <Route path={routes.history} element={<HistoryRoute />} />
    <Route path={routes.manualSearch} element={<ManualSearchRoute />} />
    <Route path={routes.unauthorized} element={<UnauthorizedRoute />} />
    <Route path="*" element={<Fallback />} />
  </Routes>
);

export default Routing;
