import React from 'react';
import { useLocation } from 'react-router-dom';
import RouteParameter from '../../components/RouteParameter';
import Layout from '../../features/Layout';
import Header from '../../features/Header';
import Footer from '../../features/Footer';

const ManualSearch = React.lazy(() => import('../../views/ManualSearch'));

const params = [];
const ManualSearchRoute = () => {
  const location = useLocation();

  return (
    <RouteParameter location={location} params={params} fallback={null}>
      <Layout header={<Header />} footer={<Footer />}>
        <ManualSearch />
      </Layout>
    </RouteParameter>
  );
};

ManualSearchRoute.propTypes = {};

ManualSearchRoute.defaultProps = {};

export default ManualSearchRoute;
