const routes = {
  dashboard: '/',
  reports: '/reports',
  history: '/history',
  manualSearch: '/manual-search',
  settings: '/settings',
  unauthorized: '/unauthorized',
  // name: '/name/name/*',
};
export default routes;
