import React, { useEffect } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import routes from '../../config/routes';
import URL from '../../utils/url';

const Fallback = () => {
  const navigate = useNavigate();

  useEffect(() => {
    navigate(new URL(routes.dashboard).appendParams({}).getUrl());
  }, []);

  return <> </>;
};

export default Fallback;
