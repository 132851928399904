import React from 'react';
import { useNavigate } from 'react-router-dom';
import MenuButton from '../../ui-components/MenuButton';
import PropTypes from 'prop-types';
import routes from '../../config/routes';
import URL from '../../utils/url';
import './style.css';

const classNames = require('classnames');

const Header = ({ toggleMenu, showHeader }) => {
  const navigate = useNavigate();

  const navigateToLink = (url) => {
    // navigate(url);
    navigate(new URL(url).appendParams({}).getUrl());
  };

  const handleLogout = () => {
    localStorage.clear();
  };

  return (
    <div
      className={classNames('h-screen fixed menu_con', {
        'menu-backdrop-show': showHeader,
        'menu-backdrop-hide': !showHeader,
      })}
    >
      <div className="backdrop" onClick={toggleMenu}>
        &nbsp;
      </div>
      <div
        className={classNames('w-full h-screen fixed z-10 header', {
          'header-show': showHeader,
          'header-hide': !showHeader,
        })}
      >
        <div className="flex items-center justify-center p-4 header-logo">
          <img
            src="/assets/icons/informed-closer-logo.png" // w-73px, h-47px
            alt="informed-closer-logo"
            width="150px"
            onClick={() => navigateToLink(routes.dashboard)}
          />
        </div>
        <div className="py-6 menu_con z-10">
          <nav>
            <MenuButton
              label="Dashboard"
              type="button"
              normal={window.location.pathname !== routes.dashboard}
              href="/"
              width="100%"
              height="56px"
              icon="dashboard"
              relativeImgPath="/assets/icons/menu-icons/"
              onClick={() => navigateToLink(routes.dashboard)}
            />
            <MenuButton
              label="Reports"
              type="button"
              normal={window.location.pathname !== routes.reports}
              href="/"
              width="100%"
              height="56px"
              icon="reports"
              relativeImgPath="/assets/icons/menu-icons/"
              onClick={() => navigateToLink(routes.reports)}
            />
            <MenuButton
              label="History"
              type="button"
              normal={window.location.pathname !== routes.history}
              href="/"
              width="100%"
              height="56px"
              icon="history"
              relativeImgPath="/assets/icons/menu-icons/"
              onClick={() => navigateToLink(routes.history)}
            />
            <MenuButton
              label="Manual Search"
              type="button"
              normal={window.location.pathname !== routes.manualSearch}
              href="/"
              width="100%"
              height="56px"
              icon="manual-search"
              relativeImgPath="/assets/icons/menu-icons/"
              onClick={() => navigateToLink(routes.manualSearch)}
            />
            {/* <MenuButton
              label="Settings"
              type="button"
              normal={window.location.pathname !== routes.settings}
              href="/"
              width="100%"
              height="56px"
              icon="settings"
              relativeImgPath="/assets/icons/menu-icons/"
              onClick={() => navigateToLink(routes.settings)}
            /> */}
            {/* <MenuButton
              label="Dashboard"
              type="button"
              normal={window.location.pathname !== ''}
              href="/"
              width="100%"
              height="56px"
              icon="dashboard"
              relativeImgPath="/assets/icons/menu-icons/"
              onClick={() => navigateToLink('/hello')}
            /> */}
            {/* <MenuButton
              label="Dashboard"
              type="button"
              normal={window.location.pathname !== ''}
              href="/"
              width="100%"
              height="56px"
              icon="dashboard"
              relativeImgPath="/assets/icons/menu-icons/"
              onClick={() => navigateToLink('/hello')}
            /> */}
            {/* <MenuButton
              label="Dashboard"
              type="button"
              normal={window.location.pathname !== routes.dashboard}
              href="/"
              width="100%"
              height="56px"
              icon="dashboard"
              relativeImgPath="/assets/icons/menu-icons/"
              onClick={() => navigateToLink(routes.dashboard)}
            /> */}
            {/* <MenuButton */}
            {/*  label="Settings" */}
            {/*  type="button" */}
            {/*  normal={window.location.pathname !== '/settings'} */}
            {/*  href="/settings" */}
            {/*  width="100%" */}
            {/*  height="56px" */}
            {/*  icon="settings" */}
            {/*  relativeImgPath="/assets/icons/menu-icons/" */}
            {/*  // onClick={() => navigateToLink('/settings')} */}
            {/* /> */}
            {/* <MenuButton
              label="Log Out"
              type="button"
              normal={true}
              href="#"
              width="100%"
              height="56px"
              icon="log-out"
              relativeImgPath="/assets/icons/menu-icons/"
              onClick={handleLogout}
            /> */}
          </nav>
        </div>
      </div>
    </div>
  );
};

Header.propTypes = {
  toggleMenu: PropTypes.func,
  showHeader: PropTypes.bool,
};

Header.defaultProps = {
  toggleMenu: undefined,
  showHeader: false,
};

export default Header;
